import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./context/ContextProvider";
import { Router } from "./routes/Routing";
import { routes } from "./routes/RouteConfig";
import { ApolloProvider, client } from "./graphql/config";

import "./App.scss";

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AppContextProvider>
          <Router routes={routes} />
        </AppContextProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
