import React, {
  ComponentType,
  lazy,
  LazyExoticComponent,
  ReactNode,
} from "react";


export interface IRoute {
  // Path, like in basic prop
  label: string;
  path: string;
  icon?: string;
  location?: any;
  staffOnly?: boolean;
  computedMatch?: any;
  shortDesc?: string;
  longDesc?: string;
  // Exact, like in basic prop
  exact: boolean;
  // Preloader for lazy loading
  fallback: NonNullable<ReactNode> | null;
  // Lazy Loaded component
  component?: LazyExoticComponent<ComponentType<any>>;
  SidePanel?: LazyExoticComponent<ComponentType<any>>;
  // Sub routes
  routes?: IRoute[];
  // Redirect path
  redirect?: string;
  // If router is private, this is going to be true
  private?: boolean;
}

export const routes: IRoute[] = [
  {
    label: "LoginRedirect",
    fallback: <div>Loading... </div>,
    exact: false,
    private: false,
    staffOnly: false,
    path: "/auth/:providerName/callback",
    component: lazy(() => import("./LoginRedirect/LoginRedirect")),
  },
  {
    label: "Default",
    fallback: <div> Loading... </div>,
    exact: true,
    private: true,
    staffOnly: false,
    path: "/",
    component: lazy(() => import("./Default/Default")),
  },
  {
    label: "Tenant",
    fallback: <div> Loading... </div>,
    exact: false,
    private: true,
    staffOnly: false,
    path: "/:tenantId",
    component: lazy(() => import("./Default/Default")),
    routes: [
      {
        label: "Create LO",
        fallback: <div> Loading... </div>,
        exact: true,
        private: true,
        staffOnly: true,
        path: "/:tenantId/create-lo",
        component: lazy(() => import("./AddEditLo/AddEditLo")),
      },
      {
        label: "Edit LO",
        fallback: <div> Loading... </div>,
        exact: false,
        private: true,
        staffOnly: true,
        path: "/:tenantId/create-lo/:documentID",
        component: lazy(() => import("./AddEditLo/AddEditLo")),
      },
      {
        label: "Dashboard",
        fallback: <div> Loading... </div>,
        exact: true,
        private: true,
        staffOnly: true,
        path: "/:tenantId",
        component: lazy(() => import("./Dashboard/Dashboard")),
      },
      {
        label: "DocLink",
        fallback: <div> Loading... </div>,
        exact: true,
        private: true,
        staffOnly: false,
        path: "/:tenantId/:documentID",
        component: lazy(() => import("./DocLink/DocLink")),
      },
    ],
  },
];
