import React from 'react';
import Helmet from 'react-helmet';
import { simpleDecode } from "../Security/Helpers";

const TitleComponent = ( props: any ) => {
    let title = props?.title.toString();
    const defaultTitle = 'Learning Object Repository';
    const tenant = simpleDecode(window.localStorage.getItem('tenant'));
    title = title ? title : (tenant?.org_name ? `${tenant.org_name} | ${defaultTitle}` : defaultTitle);
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export { TitleComponent };