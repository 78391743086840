import React, { Suspense } from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import { IRoute } from "./RouteConfig";
import { TitleComponent } from '../components/Title/Title'
import { checkTenantOrg, checkTenantStaff } from "../components/Security/Helpers";
import LoginStart from "./LoginRedirect/LoginStart";

interface IProps {
  routes: IRoute[];
}

export const Router: React.FC<IProps> = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route: IRoute) => {
        return <RouteWithSubRoutes key={route.path} {...route} />;
      })}
    </Switch>
  );
};

export const RouteWithSubRoutes = (route: IRoute) => {
  /** Authenticated flag */
  let { tenantId } = useParams<any>();
  tenantId = tenantId ? tenantId : route.computedMatch.params.tenantId;
  const authToken = localStorage.getItem('authtoken');
  const tenantToken = localStorage.getItem('tenant');
  const orgMatches = checkTenantOrg(tenantToken, tenantId);
  const authenticated = authToken && tenantToken && orgMatches;
  const authenticatedOrAuthenticating = authenticated || (route.label && route.label === 'LoginRedirect');
  
  if (!authenticatedOrAuthenticating) { // if going to LoginStart
    // save the desired path for post authentication
    localStorage.setItem('lorRoutePath', route.location.pathname);
  }

  return (
    <Suspense fallback={route.fallback}>
      <Route
        path={route.path}
        render={(props) =>
          route.redirect ? (
            <Redirect to={route.redirect} />
          ) : route.private ? (
            // if authenticated or if in the authentication process, follow the routeConfig
            authenticatedOrAuthenticating ? (
              // if this is not the Dashboard page (home page / All Documents Table page) OR if it is that page and the user has permission to view that page, then go to it
              !route.staffOnly || checkTenantStaff(tenantToken) ?
                (route.component && (
                    <React.Fragment>
                      <TitleComponent title={``} />
                      <route.component {...props} routes={route.routes} />
                    </React.Fragment>
                  )
                ) : ( // else user does not have permission to view the page and will be informed of this
                  <h1>You do not have permission to view this page.</h1>
                )
            ) : ( // else redirect to /connect/sgp to start authentication process
              <LoginStart />
            )
          ) : (
            route.component && (
              <React.Fragment>
                <TitleComponent title={``} />
                <route.component {...props} routes={route.routes} />
              </React.Fragment>
            )
          )
        }
      />
    </Suspense>
  );
};
