import { useEffect } from "react";

const loginUrl =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_LOGIN_URL_DEV}`
    : `${process.env.REACT_APP_LOGIN_URL_PROD}`;

const LoginStart: React.FC = (args: any) => {
  useEffect(() => {
    window.location.href = loginUrl;  
  }, []);

  return <p>Authenticating...</p>;
};

export default LoginStart;
