import React, { useReducer, createContext } from "react";
// import { routes } from "../routes/RouteConfig";
// import { useLocation } from "react-router-dom";

// Create Context Object
export const AppContext = createContext();

let initialState = {
  tenant: null,
  managerView: "table",
  table: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: "updated_at",
    sortDirection: "desc",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_TENANT":
      return {
        ...state,
        tenant: { ...action.payload },
      };
    case "UPDATE_MANAGER_VIEW":
      return {
        ...state,
        managerView: action.payload.view,
      };
    case "CHANGE_TABLE_PAGE":
      return {
        ...state,
        table: { ...state.table, currentPage: action.payload.currentPage },
      };
    case "CHANGE_TABLE_SORT":
      return {
        ...state,
        table: {
          ...state.table,
          sortDirection: action.payload.sortDirection,
          sortBy: action.payload.sortBy,
        },
      };
    case "CHANGE_ITEMS_PER_PAGE":
      return {
        ...state,
        table: {
          ...state.table,
          itemsPerPage: action.payload.itemsPerPage,
        },
      };
    case "REFETCH_DATA":
      return {
        ...state,
        table: {
          ...state.table,
          refetch: action.payload.refetch,
        },
      };
    default:
      throw new Error();
  }
};

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  );
};
