import btoa from "btoa";
import atob from "atob";

const TENANT_PAGE_WHITELIST_ROLES = ['STAFF', 'ADMIN', 'TUTOR', 'TEACHER', 'INSTRUCTOR', 'MENTOR'];

interface JwtExpects {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string; // not currently included
  session_state: string;
  at_hash: string;
  acr: string;
  user_uuid: string;
  user_org_name_display: string; // not currently included
  name: string; // not currently included
  prefferred_username: string; // not currently included
  email_verified: boolean;
  user_id: string;
  user_org_name: string;
  user_roles: string[];
  email: string;
  given_name: string; // not currently included
  family_name: string; //not currently included
}

export const checkTenant = (launchToken: JwtExpects, orgIdInUrl: string) => {
  return launchToken.user_org_name === orgIdInUrl;
};

export const checkTenantOrg = (tenantToken: any, orgIdInUrl: string) => {
  const decodedTenantToken = simpleDecode(tenantToken);
  const tenantOrg = decodedTenantToken ? decodedTenantToken.org_key : decodedTenantToken;
  return tenantOrg === orgIdInUrl;
};

export const checkTenantStaff = (tenantToken: any) => {
  const decodedTenantToken = simpleDecode(tenantToken);
  return decodedTenantToken && decodedTenantToken.org_roles && decodedTenantToken.org_roles.some((role: string) => TENANT_PAGE_WHITELIST_ROLES.includes(role));
};

const urlBase64Decode = (str: string) => {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      // tslint:disable-next-line:no-string-throw
      // eslint-disable-next-line no-throw-literal
      throw "Illegal base64url string!";
  }
  return decodeURIComponent(window.escape(window.atob(output)));
};

export const decodeToken = (token: string = "") => {
  if (token === null || token === "") {
    return { upn: "" };
  }
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("JWT must have 3 parts");
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error("Cannot decode the token");
  }
  return JSON.parse(decoded);
};

export const simpleEncode = (toEncode: any) => {
  const newToEncode =
    typeof toEncode === "object" ? JSON.stringify(toEncode) : toEncode;
  return btoa(newToEncode);
};

export const simpleDecode = (toDecode: any) => {
  try {
    if (typeof toDecode === 'object') {
      return toDecode;
    }
    toDecode = toDecode && typeof toDecode === 'string' ? toDecode.replace(/^"(.*)"$/, '$1') : toDecode;
    return JSON.parse(atob(toDecode));
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
